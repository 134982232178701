import React from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from '@chakra-ui/react';

import getProjectsForCreator from 'app/data/projects/queries/getProjectsForCreator';
import userGetProjects from 'app/data/requests/queries/public/userGetProjects';
import { AwaitReturn } from 'app/types/util';
import { useMagicalQuery } from 'app/utils/blitz-utils';
import { useCurrentUserQuery } from 'app/hooks/useCurrentUser';
import CreatedProjectList from 'app/components/UserDashboard/CreatedProjectList';
import InterestedProjectsList from 'app/components/UserDashboard/InterestedProjectsList';
import * as L from 'app/styles/layout-styled-components';
import Modal from 'app/components/Modal';
import UpdateUserCareerRole from 'app/components/UpdateUserCareerRole';

export type ProjectType = AwaitReturn<typeof getProjectsForCreator>[number];

const UserDashboard = () => {
  const [projects] = useMagicalQuery(getProjectsForCreator, {});
  const [projectsOfInterest] = useMagicalQuery(userGetProjects, {});
  const [user] = useCurrentUserQuery();

  const modalDisclosure = useDisclosure({ defaultIsOpen: true });

  return (
    <L.Vertical debug="Dashboard" full spacing={100} flex={1}>
      {!user?.careerRole && (
        <Modal size="2xl" disclosure={modalDisclosure}>
          <UpdateUserCareerRole onSubmit={modalDisclosure.onClose} />
        </Modal>
      )}
      <Tabs colorScheme="purple">
        <TabList>
          <Tab>Created projects</Tab>
          <Tab>Projects of interest</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <CreatedProjectList projects={projects} />
          </TabPanel>
          <TabPanel>
            <InterestedProjectsList projects={projectsOfInterest} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </L.Vertical>
  );
};

export default UserDashboard;
