import {
  FormControl,
  FormErrorIcon,
  FormErrorMessage,
  FormLabel,
  SimpleGrid,
  SimpleGridProps,
  Text,
  useRadioGroup,
} from '@chakra-ui/react';
import { forwardRef } from 'react';
import { useFormContext } from 'react-hook-form';
import * as L from 'chakra-layout-components';
import RadioItem from './RadioItem';
import { Disable } from 'react-disable';

export type RadioOptions = {
  label: string;
  value: any;
};

export interface LabeledTextFieldProps {
  name: string;
  label: string;
  outerProps?: any;
  options: RadioOptions[];
}

export type RadioGridProps = LabeledTextFieldProps & Partial<SimpleGridProps>;

export const RadioGrid = forwardRef<HTMLDivElement, RadioGridProps>(
  ({ label, outerProps, options, ...props }) => {
    const {
      register,
      setValue,
      formState: { isSubmitting, errors },
    } = useFormContext();

    const { name } = props;

    const handleChange = (value) => {
      setValue(name as any, value);
    };

    const { getRootProps, getRadioProps } = useRadioGroup({
      ...register(name as any),
      onChange: handleChange,
    });

    const error = Array.isArray(errors[name])
      ? errors[name].join(', ')
      : errors[name]?.message || errors[name];

    return (
      <Disable disabled={isSubmitting}>
        <FormControl width="100%" isInvalid={!!error}>
          <SimpleGrid
            columns={[2, 3]}
            w="100%"
            {...getRootProps()}
            borderRadius="lg"
            overflow="hidden"
            border="1px solid"
            borderColor="gray.600"
            {...props}
          >
            {options?.map(({ value, label }) => {
              const radio = getRadioProps({ value });
              return (
                <RadioItem key={value} {...radio}>
                  {label}
                </RadioItem>
              );
            })}
          </SimpleGrid>

          <FormErrorMessage>
            <FormErrorIcon />
            <Text>{error}</Text>
          </FormErrorMessage>
        </FormControl>
      </Disable>
    );
  }
);

export default RadioGrid;
