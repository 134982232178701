import React from 'react';
import { z } from 'zod';
import { Text } from '@chakra-ui/react';
import * as L from 'chakra-layout-components';

import updateUserCareerRole from 'app/data/user/mutations/updateUserCareerRole';
import { RadioGrid } from 'app/components/RadioGrid';
import { careerRoles, CareerRole } from 'app/data/user/config/career-roles';
import { useMagicalMutation } from 'app/utils/blitz-utils';
import Form from 'app/components/Form';

const FormSchema = z.object({
  careerRole: z.nativeEnum(CareerRole).optional(),
});

const UpdateUserCareerRole: React.FC<{ onSubmit: () => void }> = ({ onSubmit }) => {
  const [updateCareerRoleMutation] = useMagicalMutation(updateUserCareerRole, {
    successToastMessage: 'Thank you!',
  });

  const updateUserInfo = async (values) => {
    await updateCareerRoleMutation(values);
    onSubmit();
  };

  return (
    <L.Vertical centerH spacing={30} textAlign="center">
      <Text maxWidth={600} width="100%" fontSize="lg">
        To help us understand your needs better, please choose which option describes your current
        role?
      </Text>
      <L.Vertical spacing={10}>
        <Form submitOnChange schema={FormSchema} onSubmit={updateUserInfo}>
          <RadioGrid name="careerRole" label="" options={careerRoles} />
        </Form>
      </L.Vertical>
    </L.Vertical>
  );
};

export default UpdateUserCareerRole;
