import { RadioProps, useRadio } from '@chakra-ui/radio';
import * as L from 'chakra-layout-components';

const RadioItem: React.FC<RadioProps> = (props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <L.Vertical as="label" cursor="pointer">
      <input {...input} />
      <L.Horizontal
        p={5}
        {...checkbox}
        opacity={0.8}
        _hover={{
          bg: 'whiteAlpha.100',
        }}
        _checked={{
          bg: 'gray.700',
          opacity: 1,
        }}
        fullH
        transition="all 100ms linear"
        outline="1px solid"
        outlineColor="gray.600"
        center
        fontSize="lg"
      >
        {props.children}
      </L.Horizontal>
    </L.Vertical>
  );
};

export default RadioItem;
