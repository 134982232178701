import React from 'react';
import { Heading, Text } from '@chakra-ui/react';
import { Link } from 'chakra-next-link';

import { ProjectType } from './index';
import Card from 'app/components/Card';
import ProjectLogo from 'app/components/ProjectLogo';
import * as L from 'app/styles/layout-styled-components';

const Project: React.FC<{ project: ProjectType }> = ({ project }) => {
  const { description, name, creator } = project;

  return (
    <Link href={`/${creator.username}/${project.slug}`} noUnderline w="full">
      <Card>
        <L.Horizontal flex={1} center spacing={10}>
          <ProjectLogo size={45} emoji={project.emoji} logo={project.logoImage} />
          <L.Vertical flex={1} spacing={2}>
            <Heading size="lg">{name}</Heading>
            {description && <Text fontSize="14px">{description}</Text>}
          </L.Vertical>
        </L.Horizontal>
      </Card>
    </Link>
  );
};

export default Project;
