import React from 'react';
import { Text } from '@chakra-ui/react';
import { LinkButton } from 'chakra-next-link';
import pluralize from 'pluralize';

import { ProjectType } from './index';
import { useCurrentUser } from 'app/hooks/useCurrentUser';
import { has } from 'app/utils/utils';
import CreatedProject from './CreatedProject';
import * as L from 'app/styles/layout-styled-components';

const CreatedProjectList: React.FC<{ projects: ProjectType[] }> = ({ projects }) => {
  let hasProjects = has(projects);
  const user = useCurrentUser();
  if (!user) return null;

  const { canCreateNewProject, isAdmin, projectsLeftToCreate } = user;

  return (
    <L.Vertical fullW spacing={20}>
      <L.Vertical spacing={10}>
        {!hasProjects && (
          <>
            <Text>You haven't created any projects.</Text>
            {!canCreateNewProject && (
              <Text>
                For now, project creation is invite-only and we'll open it for public access soon.
              </Text>
            )}
          </>
        )}
        {canCreateNewProject && (
          <>
            {!isAdmin && (
              <Text size="13px">
                You can create {projectsLeftToCreate} more{' '}
                {pluralize('project', projectsLeftToCreate)}.
              </Text>
            )}
            <LinkButton colorScheme="purple" size="md" href={`/projects/new`}>
              Create a project
            </LinkButton>
          </>
        )}
      </L.Vertical>

      <L.Vertical fullW spacing={15}>
        {projects.map((project) => (
          <CreatedProject key={project.id} project={project} />
        ))}
      </L.Vertical>
    </L.Vertical>
  );
};

export default CreatedProjectList;
