export enum CareerRole {
  Developer = 'Developer',
  IndieMaker = 'IndieMaker',
  Freelancer = 'Freelancer',
  TechLead = 'TechLead',
  ContentWriter = 'ContentWriter',
  Designer = 'Designer',
  Founder = 'Founder',
  Manager = 'Manager',
  Other = 'Other',
}

export const careerRoles: Array<{ value: CareerRole; label: string }> = [
  { value: CareerRole.Developer, label: 'Developer' },
  { value: CareerRole.Designer, label: 'Designer' },
  { value: CareerRole.Founder, label: 'Founder / CEO / CTO' },
  { value: CareerRole.Freelancer, label: 'Freelancer' },
  { value: CareerRole.IndieMaker, label: 'Indie Maker' },
  { value: CareerRole.ContentWriter, label: 'Content Writer' },
  { value: CareerRole.TechLead, label: 'Tech Lead' },
  { value: CareerRole.Manager, label: 'Manager' },
  { value: CareerRole.Other, label: 'Other' },
];
