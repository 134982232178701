import { BlitzPage } from '@blitzjs/next';
import React from 'react';

import { useCurrentUser } from 'app/hooks/useCurrentUser';
import UserDashboard from 'app/components/UserDashboard';
import Welcome from 'app/components/Welcome';
import Layout from 'app/layouts/Layout';

const Home: BlitzPage = () => {
  const user = useCurrentUser();

  return (
    <>
      {!user && <Welcome />}
      {user && <UserDashboard />}
    </>
  );
};

Home.getLayout = (page) => <Layout>{page}</Layout>;

export default Home;
