import { useQuery } from '@blitzjs/rpc';
import React from 'react';
import { Heading } from '@chakra-ui/react';
import { LinkButton } from 'chakra-next-link';
import { Reveal, RevealMode } from 'react-genie';
import { Animation } from 'react-genie-with-emotion';

import getHomePageProjects from 'app/data/projects/queries/getHomePageProjects';
import { appName } from 'app/config/appName';
import * as L from 'app/styles/layout-styled-components';

const Welcome = () => {
  const [project, { isLoading }] = useQuery(getHomePageProjects, {}, { suspense: false });

  return (
    <Reveal mode={RevealMode.Clone} animation={Animation.FadeInUp}>
      <L.Vertical fullW center>
        <L.Vertical maxWidth={400} fullW center spacing={15}>
          <L.Vertical center spacing={10}>
            <Heading size="2xl">{appName}</Heading>
            <Heading fontWeight={300} fontSize="1.5em">
              Keep your users in the loop
            </Heading>
          </L.Vertical>
          <L.Horizontal spacing={10}>
            <LinkButton
              isDisabled={!project}
              isLoading={isLoading}
              href={`${project?.creator?.username}/${project?.slug}`}
            >
              See an example
            </LinkButton>
            <LinkButton href="/signup" colorScheme="purple">
              Sign up
            </LinkButton>
          </L.Horizontal>
        </L.Vertical>
      </L.Vertical>
    </Reveal>
  );
};

export default Welcome;
