import React from 'react';

import getSuggestedProjects from 'app/data/projects/queries/getSuggestedProjects';
import { useMagicalQuery } from 'app/utils/blitz-utils';
import { ProjectList } from './ProjectList';

export const SuggestedProjects = () => {
  const [projects] = useMagicalQuery(getSuggestedProjects, {});
  return <ProjectList projects={projects} />;
};
