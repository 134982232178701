import React from 'react';
import { Heading, Text } from '@chakra-ui/react';

import { ProjectList } from 'app/components/UserDashboard/ProjectList';
import { has } from 'app/utils/utils';
import { ProjectType } from './index';
import { SuggestedProjects } from './SuggestedProjects';
import * as L from 'app/styles/layout-styled-components';

const InterestedProjectsList: React.FC<{ projects: ProjectType[] }> = ({ projects }) => {
  if (!has(projects))
    return (
      <L.Vertical debug="Project list" fullW spacing={10}>
        <Text>You're not participating in any projects yet. Here are some suggestions:</Text>
        <SuggestedProjects />
      </L.Vertical>
    );

  return (
    <L.Vertical debug="Project list" fullW spacing={10}>
      <Heading size="md">Projects of interest</Heading>
      <ProjectList projects={projects} />
    </L.Vertical>
  );
};

export default InterestedProjectsList;
